import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { verifyPassword } from 'api/auth';
import { getMeMfa } from 'api/mfa';
import cn from 'classnames';
import { useAppDispatch, useAppSelector, useBreakPoint } from 'hooks';
import { QRCodeSVG } from 'qrcode.react';
import { checkMfaParamsThunk } from 'store/slices/auth/actions';
import { authSelector } from 'store/slices/auth/selectors';

import { CopyIcon } from 'components/CopyIcon';
import { Button, Modal, TextInput } from 'components/ui';
import { notify } from 'utils';

interface TwoFactorModalProps {
  onClose: () => void;
}

export const TwoFactorModal: FC<TwoFactorModalProps> = ({ onClose }) => {
  const [password, setPassword] = useState('');
  const [generatedCode, setGeneratedСode] = useState('');
  const [secretKey, setSecretKey] = useState('');
  const [qrcodeUrl, setQrcodeUrl] = useState('');

  const [isConfirmed, setConfirmed] = useState(false);

  const { mfaState, mfaEnabled, email } = useAppSelector(authSelector);

  const breakPoint = useBreakPoint();

  const dispatch = useAppDispatch();

  const navigate = useNavigate();

  const handlePasswordChange = (value: string) => {
    setPassword(value);
  };

  const handleConfirmButtonClick = async () => {
    if (password && email) {
      try {
        const { data } = await verifyPassword({ email, password });

        if (data.verified && mfaState) {
          if (!mfaEnabled) {
            const {
              data: { url },
            } = await getMeMfa(mfaState);

            const secret = url.split('secret=')[1];

            setSecretKey(secret);

            setQrcodeUrl(url);
          }

          setConfirmed(true);
        } else {
          notify.error('Данные не верны');
        }
      } catch (error) {
        notify.error('Не удалось подтвердить данные. Попробуйте снова');
      }
    }
  };

  const handleGeneratedСodeChange = (value: string) => {
    setGeneratedСode(value);
  };

  const handleDownload = () => {
    window.open('https://yandex.ru/id/key/main', '_blank');
  };

  const handleConnect = async () => {
    if (generatedCode && mfaState) {
      dispatch(
        checkMfaParamsThunk({
          code: generatedCode,
          mfa_state: mfaState,
        })
      );

      onClose();
    }
  };

  const handleCancel = () => {
    navigate('/');

    onClose();
  };

  const isMobile = breakPoint === 'mobile' || breakPoint === 'tablet';

  return (
    <Modal onClose={onClose} isBlurred closeOnOutsideClick={false}>
      <div
        className={cn(
          'flex flex-col gap-[24px] w-[637px] justify-center border border-solid border-tpg_light rounded-[10px] bg-dark p-[48px]',
          { '!p-[24px] !gap-[18px]': isMobile }
        )}
      >
        <span className={cn('tpg-h4', { 'text-center': isMobile })}>
          Подключение при помощи приложения
        </span>
        {!mfaEnabled && !isConfirmed && (
          <>
            <div
              className={cn('flex flex-row items-center gap-[12px]', {
                'flex-col !gap-[8px]': isMobile,
              })}
            >
              <div className="flex flex-row gap-[8px] tpg-c2">
                <div className="flex items-center justify-center min-w-[20px] h-[20px] bg-dark_product rounded-[50px]">
                  <span>1</span>
                </div>
                <span>Подтверждение аккаунта</span>
              </div>
              <div
                className={cn('h-[1px] grow bg-tpg_light', {
                  '!h-[30px] w-[1px]': isMobile,
                })}
              />
              <div className="flex flex-row gap-[8px] tpg-c2 text-tpg_base">
                <div className="flex items-center justify-center min-w-[20px] h-[20px] bg-tpg_light rounded-[50px]">
                  <span>2</span>
                </div>
                <span>Подключение приложения</span>
              </div>
            </div>
            <div className="flex flex-col gap-[12px]">
              <span className="tpg-input">
                Для подключения способа подтвердите, что этот аккаунт
                принадлежит вам
              </span>
              <div className="flex flex-row justify-between items-center h-[40px] px-[16px] py-[10px] bg-light rounded-[5px]">
                <TextInput
                  onChange={handlePasswordChange}
                  value={password}
                  placeholder="Введите пароль от аккаунта"
                  inputClassName="h-[20px] !pl-0"
                  className="w-full"
                  isPassword={true}
                />
              </div>
            </div>
            <div className="flex flex-row gap-[12px]">
              <Button
                onClick={handleCancel}
                title="Отменить"
                className="w-full hover:bg-dark_product bg-inherit border-2 border-solid border-dark_product"
              />
              <Button
                onClick={handleConfirmButtonClick}
                title="Подтвердить"
                className="w-full"
                disabled={!password}
              />
            </div>
          </>
        )}
        {(isConfirmed || mfaEnabled) && mfaState && (
          <>
            {!mfaEnabled && (
              <>
                <div
                  className={cn('flex flex-row items-center gap-[12px]', {
                    'flex-col !gap-[8px]': isMobile,
                  })}
                >
                  <div className="flex flex-row gap-[8px] tpg-c2">
                    <div className="flex items-center justify-center min-w-[20px] h-[20px] bg-tpg_light rounded-[50px]">
                      <span>1</span>
                    </div>
                    <span className="text-tpg_base">
                      Подтверждение аккаунта
                    </span>
                  </div>
                  <div
                    className={cn('h-[1px] grow bg-tpg_light', {
                      '!h-[20px] w-[1px]': isMobile,
                    })}
                  />
                  <div className="flex flex-row gap-[8px] tpg-c2">
                    <div className="flex items-center justify-center min-w-[20px] h-[20px] bg-dark_product rounded-[50px]">
                      <span>2</span>
                    </div>
                    <span>Подключение приложения</span>
                  </div>
                </div>
                <div className="flex flex-col gap-[12px]">
                  <span className="tpg-input">
                    1. Отсканируйте QR-код в любом приложении-аутентификаторе (
                    <span
                      onClick={handleDownload}
                      className="text-bright_product cursor-pointer"
                    >
                      скачать
                    </span>
                    )
                  </span>
                  <QRCodeSVG
                    value={qrcodeUrl}
                    size={isMobile ? 110 : 210}
                    marginSize={2}
                  />
                  <span className="tpg-input">
                    Если не получается отсканировать код, введите секретный ключ
                    в приложении вручную
                  </span>
                  <div className="flex flex-row gap-[10px] items-center">
                    <span className="text-bright_product tpg-c2">
                      {secretKey}
                    </span>
                    <CopyIcon value={secretKey} />
                  </div>
                </div>
              </>
            )}
            <div className="flex flex-col gap-[12px]">
              <span className="tpg-input">
                {`${
                  mfaEnabled ? '' : '2.'
                } Введите сгенерированный в приложении 6-значный код`}
              </span>
              <div className="flex flex-row justify-between items-center h-[40px] px-[16px] py-[10px] bg-light rounded-[5px]">
                <TextInput
                  onChange={handleGeneratedСodeChange}
                  value={generatedCode}
                  placeholder="Код из приложения"
                  inputClassName="h-[20px] !pl-0"
                  className="w-full"
                  maxLength={6}
                />
              </div>
            </div>
            <div className="flex flex-row gap-[12px]">
              <Button
                onClick={handleCancel}
                title="Отменить"
                className="w-full hover:bg-dark_product bg-inherit border-2 border-solid border-dark_product"
              />
              <Button
                onClick={handleConnect}
                title="Подключить"
                className="w-full"
                disabled={!generatedCode || generatedCode.length !== 6}
              />
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};
